import React from 'react';
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function Sitemap(){

    return(
        <Layout>
            <SEO 
                title="Sitemap"
                description="Sitemap for Savante Consulting Limited official website."
                />
    
            <div className="container">
            <br />
                <br />
                <ul>
                    <li><a href="/" >Home</a></li>
                    <li><a href="/services/our-services" >Our Services</a></li>
                    <li><a href="/services/regulatory-affairs" >Regulatory Affairs</a></li>
                    <li><a href="/services/clinical-trails" >Clinical Trials</a></li>
                    <li><a href="/services/pharmacovigilance" >Pharmacovigilance</a></li>
                    <li><a href="/our-clients" >Our Clients</a></li>

                    <li><a href="/Articles" >Articles</a></li>
                    <li><a href="/contact-us" >Contact Us</a></li>
                    <li><a href="/careers" >Careers</a></li>
                    <li><a href="/about-us" >About Us</a></li>

                    <li><a href="/other/privacy-policy" >Privacy Policy</a></li>
                    <li><a href="/other/sitemap" >Sitemap</a></li>
                </ul>
                <br />
               
            </div>
        </Layout>
    );
}